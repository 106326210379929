export default function useGetImageUrl(src, width = '', height = '', format = '', quality = '', crop = 'center') {
  let newSrc = src;

  if (newSrc) {
    newSrc = newSrc.replace('/storage/', '/images/');
    const checkUrlParamaters = function (url) {
      let character = '';

      if (!url.includes('?')) {
        character = '?';
      } else {
        character = '&';
      }
      return character;
    };

    if (width) {
      newSrc += checkUrlParamaters(newSrc) + 'w=' + width;
    }

    if (height) {
      newSrc += checkUrlParamaters(newSrc) + 'h=' + height;
    }

    if (format) {
      newSrc += checkUrlParamaters(newSrc) + 'fm=' + format;
    }

    quality = !quality ? 71 : quality;
    newSrc += checkUrlParamaters(newSrc) + 'q=' + quality;

    if (crop != 'center') {
      crop = !crop ? 'center' : crop;
      newSrc += checkUrlParamaters(newSrc) + 'fit=crop-' + crop.toLowerCase();
    }
  }

  return newSrc;
}
